export default class ReservaCartao {
  constructor(
    id = null,
    matricula = null,
    consignataria = null,
    rubrica = null,
    valor = null,
  ) {
    ;(this.id = id),
      (this.matricula = matricula),
      (this.consignataria = consignataria),
      (this.rubrica = rubrica),
      (this.valor = valor)
  }
}
