<template>
  <div class="grid">
    <div class="col-12">
      <div
        v-if="auth.hasRoleConsignatariaAdmin() || auth.hasRoleReservaCartao()">
        <Panel header="Reserva de cartão ou de adiantamento" class="mt-3">
          <Toolbar class="mb-4">
            <template #start>
              <Button
                label="Voltar"
                icon="pi pi-chevron-left"
                class="mt-2 mr-2 p-button-sm p-button-secondary"
                @click="$router.back()" />
            </template>
          </Toolbar>
          <form @submit.prevent="validate()">
            <div class="p-fluid formgrid grid">
              <div class="field col-6 md:col-6">
                <label for="rubrica" class="required">Rubrica</label>
                <Dropdown
                  id="rubrica"
                  v-model="v$.reservaCartao.rubrica.$model"
                  :class="{
                    'p-invalid': submitted && v$.reservaCartao.rubrica.$invalid,
                  }"
                  optionLabel="nome"
                  :options="rubricas"
                  placeholder="Selecione uma rubrica">
                  <template #value="slotProps">
                    <div v-if="slotProps.value">
                      <span>
                        {{ slotProps.value.rubrica }} |
                        {{ slotProps.value.nome }} |
                        {{ slotProps.value.atividade.nome }}
                      </span>
                    </div>
                    <div v-else>
                      <span>{{ slotProps.placeholder }}</span>
                    </div>
                  </template>

                  <template #option="slotProps">
                    <div>
                      <span>
                        {{ slotProps.option.rubrica }} |
                        {{ slotProps.option.nome }} |
                        {{ slotProps.option.atividade.nome }}
                      </span>
                    </div>
                  </template>
                </Dropdown>
                <div
                  v-if="submitted && v$.reservaCartao.rubrica.required.$invalid"
                  class="p-error">
                  O campo rubrica é obrigatório.
                </div>
              </div>
            </div>
            <div class="p-fluid formgrid grid">
              <div class="field col-6 md:col-6">
                <label for="valorParcela" class="required"
                  >Valor da Reserva</label
                >
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">R$</span>
                  <InputNumber
                    id="valor"
                    v-model="v$.reservaCartao.valor.$model"
                    :class="{
                      'p-invalid': submitted && v$.reservaCartao.valor.$invalid,
                    }"
                    mode="decimal"
                    locale="pt-BR"
                    :minFractionDigits="2"
                    :maxFractionDigits="5"
                    placeholder="0,00" />
                </div>
                <div
                  v-if="submitted && v$.reservaCartao.valor.required.$invalid"
                  class="p-error">
                  O campo Valor é obrigatório.
                </div>
              </div>
            </div>
            <Button type="submit" autofocus :disabled="saving">
              <span v-if="!saving" class="pi pi-credit-card"></span>
              <span v-if="saving" class="pi pi-spin pi-spinner"></span>
              <span v-if="!saving" class="ml-2">Inserir reserva</span>
              <span v-if="saving" class="ml-2">Aguarde</span>
            </Button>
          </form>
        </Panel>
      </div>
      <div v-else>
        <Panel header="Reserva de cartão" class="mt-3">
          <h6>
            {{ auth.msgPermissao() }}
          </h6>
        </Panel>
      </div>
    </div>
    <modal-senha-servidor
      :exibir="exibirModalSenhaServidor"
      :metodoSalvar="salvarReservaCartao"
      :metodoSalvar2="salvarPropostaReservaCartao"
      :tipo="true"
      :isReservaCartao="true"
      :showReservaCartao="reservaCartao"
      :labelConfirm1Button="'Salvar'"
      :labelConfirm2Button="'Criar Proposta'"
      @mudarVisibilidade="exibirModalSenhaServidor = $event">
    </modal-senha-servidor>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import RubricaService from '@/service/RubricaService.js'
import ReservaCartaoService from '@/service/ReservaCartaoService.js'
import MatriculaService from '@/service/MatriculaService.js'
import PropostaService from '@/service/PropostaService.js'
import ReservaCartao from '@/domain/ReservaCartao.js'
import { consignatariaStore } from '@/stores/consignataria'
import { servidorStore } from '@/stores/servidor'
import auth from '@/modules/auth'
import ModalSenhaServidor from '@/components/shared/modal/modalSenhaServidor.vue'

export default {
  components: {
    'modal-senha-servidor': ModalSenhaServidor,
  },

  setup() {
    const storeConsignataria = consignatariaStore()
    const storeServidor = servidorStore()

    return { storeConsignataria, storeServidor, v$: UseVuelidate() }
  },

  data() {
    return {
      reservaCartao: new ReservaCartao(),
      exibirModalSenhaServidor: false,
      valorReserva: [],
      'modal-senha-servidor': ModalSenhaServidor,
      submitted: false,
      submitStatus: null,
      rubrica: {},
      rubricas: [],
      consignacoes: null,
      matricula: {
        id: this.$route.params.id,
      },
      totalSomaValoresConsignacoesSelecionadas: 0,
      desativarBotao: true,
      loading: false,
      auth,
      saving: false,
      proposta: {},
    }
  },

  validations() {
    return {
      reservaCartao: {
        rubrica: {
          required,
        },
        valor: {
          required,
        },
      },
    }
  },

  created() {
    this.rubricaService = new RubricaService(this.$http)
    this.reservaCartaoService = new ReservaCartaoService(this.$http)
    this.matriculaService = new MatriculaService(this.$http)
    this.propostaService = new PropostaService(this.$http)
  },

  mounted() {
    this.carregarRubricas()
  },

  methods: {
    carregarRubricas() {
      const atividades = ['CARTAO DE CREDITO', 'ADIANTAMENTO']
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )

      this.rubricaService
        .getListaRubricasAtivasPorAtividadesDesconsiderarRubrica(
          atividades,
          this.matricula.id,
          consignataria.id,
        )
        .then((res) => {
          this.rubricas = res
        })
    },

    validate() {
      this.submitted = true
      this.v$.reservaCartao.$touch()

      if (this.v$.reservaCartao.$invalid) {
        return
      } else {
        this.exibirModalSenhaServidor = true
      }
    },

    salvarReservaCartao() {
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )
      this.atribuirDados(consignataria)
      this.saving = true

      this.reservaCartaoService
        .saveReservaCartao(this.reservaCartao, consignataria.id)
        .then(
          (res) => {
            this.storeServidor.dados = res.data.matricula
            this.exibeToast('success')
            this.limparCampos()
          },
          (err) => {
            this.saving = false
            this.exibeToast('error', err.response.data.message)
          },
        )
    },

    salvarPropostaReservaCartao() {
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )
      this.atribuirDadosProposta(consignataria)
      this.propostaService.criarPropostaReservaCartao(this.proposta).then(
        (res) => {
          this.storeServidor.dados = res.data.matricula
          this.exibeToast('proposta')
          this.limparCampos()
        },
        (err) => {
          this.saving = false
          this.exibeToast('error', err.response.data.message)
        },
      )
    },

    atribuirDadosProposta(consignataria) {
      this.proposta.valorParcela = this.reservaCartao.valor
      this.proposta.rubrica = this.reservaCartao.rubrica
      this.proposta.matricula = this.storeServidor.dados
      this.proposta.on_line = true
      this.proposta.consignataria = consignataria
    },

    atribuirDados(consignataria) {
      this.reservaCartao.consignataria = consignataria
      this.reservaCartao.matricula = this.storeServidor.dados
    },

    exibeToast(tipo, msg) {
      if (tipo === 'success') {
        this.$toast.add({
          severity: 'success',
          summary: 'Reserva de Cartão salva com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'proposta') {
        this.$toast.add({
          severity: 'success',
          summary: 'Proposta salva com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      }
    },

    limparCampos() {
      this.submitted = false
      this.saving = false
      this.v$.$reset()
      this.reservaCartao = new ReservaCartao()
    },
  },
}
</script>

<style scoped>
consignacao-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
}

.consignacao-list-detail {
  flex: 1 1 0;
}

.consignacao-detail {
  vertical-align: middle;
  line-height: 1;
}

.p-picklist-source-controls {
  display: none !important;
}

.p-picklist-buttons.p-picklist-target-controls {
  display: none !important;
}

@media screen and (max-width: 576px) {
  .consignacao-item {
    flex-wrap: wrap;
  }
}

h6 {
  font-weight: bold;
  font-style: italic;
  text-align: center;
  color: #c82333;
}
.required:after {
  content: ' *';
  color: red;
}
</style>
